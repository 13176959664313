import { Icon } from '@seeqdev/qomponents';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isAIStandalone } from '@/main/routing.utilities';
import { setDarkMode } from '@/workbench/workbench.actions';
import { sqWorkbenchStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';

interface AiAssistantToolbarProps {
  expandHistory: () => void;
  newChat: () => void;
  isRunning: boolean;
}

export const AiAssistantToolbar: React.FunctionComponent<AiAssistantToolbarProps> = ({
  expandHistory,
  newChat,
  isRunning,
}) => {
  const { t } = useTranslation();
  const darkMode = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.darkMode);

  return (
    <div className="flexColumnContainer flexAlignCenter mr9 ml5 mt10 mb10">
      <div
        className="flexColumnContainer flexAlignCenter mr15 highlightTextOnHover cursorPointer"
        onClick={expandHistory}
        data-qtip-text={t('AI_ASSISTANT.HISTORY')}>
        <Icon icon="fa-clock-rotate-left" extraClassNames="mr5" />
      </div>
      <div className="flexColumnContainer flexFill" />
      {isAIStandalone() && (
        <Icon
          icon={darkMode ? 'fa-brightness' : 'fa-moon-stars'}
          onClick={() => setDarkMode(!sqWorkbenchStore.darkMode)}
          extraClassNames="mr15 cursorPointer"
          tooltip={darkMode ? t('DARK_MODE.DISABLE') : t('DARK_MODE.ENABLE')}
          tooltipPlacement="left"
        />
      )}
      <div
        className="flexColumnContainer flexAlignCenter highlightTextOnHover cursorPointer"
        data-qtip-text={t('AI_ASSISTANT.NEW_CHAT')}
        data-qtip-placement="left"
        onClick={() => {
          !isRunning && newChat();
        }}>
        <Icon icon="fa-plus" type={isRunning ? 'gray' : 'theme'} />
      </div>
    </div>
  );
};
