import React from 'react';
import { DataHeaderProps } from '@/tableBuilder/tableBuilder.types';

const className = 'text-bolder text-center forceVerticalAlignMiddle specDataColumnHeader';
const testId = 'tableBuilderDataHeader';

export const TableBuilderDataHeader: React.FunctionComponent<DataHeaderProps> = ({ headerValue }) => {
  return (
    <div className={className} data-testid={testId} style={{ color: 'black' }}>
      {headerValue}
    </div>
  );
};
