// @ts-strict-ignore
import _ from 'lodash';
import { ASSET_PATH_SEPARATOR } from '@/main/app.constants';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import { SwapOptionListV1, SwapOptionV1 } from '@/sdk/model/models';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { warnToast } from '@/utilities/toast.utilities';
import { notifyWarning } from '@/utilities/screenshot.utilities';
import i18next from 'i18next';
import { AssetReplaceModal, AssetReplaceModalProps } from '@/search/AssetReplaceModal.molecule';
import { setShowModal } from '@/core/hooks/useModalManager.hook';
import { swapAssets } from '@/trendData/trend.actions';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { changeAssetId } from '@/tableBuilder/tableBuilder.actions';

export function getAutoSwap(swapOptionList: SwapOptionListV1, swapInId: string): SwapOptionV1 | undefined {
  if (swapsPerfectly(swapOptionList.swapOptions)) {
    return swapOptionList.swapOptions[0];
  }

  // It may be that there is more than one option, but that once we exclude identity swaps (swapping the same
  // asset that is already in the trend) which we can assume the user doesn't want, there is only one left.
  const swapOptionsWithoutIdentity = _.reject(
    swapOptionList.swapOptions,
    (option) => option.swapRootCandidate.id === swapInId,
  );
  if (swapsPerfectly(swapOptionsWithoutIdentity)) {
    return swapOptionsWithoutIdentity[0];
  }
}

/**
 * A swap can be done without user input if it is the only one in the list and all parameters of the formula
 * item can be swapped.
 */
export function swapsPerfectly(swapOptions: SwapOptionV1[]): boolean {
  return (
    swapOptions.length === 1 &&
    !_.isEmpty(swapOptions[0].itemsWithSwapPairs) &&
    _.every(swapOptions[0].itemsWithSwapPairs, (item) => item.parameterMatch === 1.0)
  );
}

export function getSwapPairs(swapOption: SwapOptionV1) {
  return _.mapValues(_.keyBy(swapOption.itemsWithSwapPairs, 'item.id'), 'swapPairs');
}

export function getSwapRootChoices(swapOptionList: SwapOptionListV1, itemId) {
  // The path length is one more than the ancestor count since we count the item itself
  const items = getAllItems({});
  const validSwapOptionIds = _.chain(swapOptionList.swapOptions)
    .filter((option) => !_.isEmpty(option.itemsWithSwapPairs))
    .map('swapRootCandidate.id')
    .value();
  // Available assets are the immediate parents of the items or their parameters
  const availableAssets = _.chain(items)
    .flatMap('assets')
    .reject(['id', itemId])
    .map((asset) =>
      _.assign(
        {
          pathComponentDisabled: _.map(asset.pathComponentIds, (id) => _.indexOf(validSwapOptionIds, id) === -1),
        },
        asset,
      ),
    )
    .reject((asset) => _.every(asset.pathComponentDisabled))
    .sortBy(['formattedName'])
    .uniqBy('id')
    .value();
  const likelySwapOuts = _.chain(availableAssets)
    .map((asset) => {
      // The likely swap-out is the path component that ranks highest in the pre-sorted swapOptions
      const id: string = _.head(
        _.sortBy(asset.pathComponentIds, (id) =>
          _.indexOf(validSwapOptionIds, id) === -1 ? Infinity : _.indexOf(validSwapOptionIds, id),
        ),
      );
      const name: string = _.nth(
        _.split(asset.formattedName, ASSET_PATH_SEPARATOR),
        _.indexOf(asset.pathComponentIds, id),
      );
      const disabled: boolean = asset.pathComponentDisabled[_.indexOf(asset.pathComponentIds, id)];
      return { id, name, disabled };
    })
    .uniqBy('id')
    .value();
  return { availableAssets, likelySwapOuts };
}

export function swapAsset(asset: { id: string; ancestors?: any[]; name?: string }, swapAssetsFn = swapAssets) {
  const swapOutItemIds = _.chain(getAllItems({})).uniqBy('id').map('id').value();

  return sqItemsApi.getSwapOptions({ id: asset.id, swapOutItemIds }).then(({ data: swapOptionList }) => {
    const autoSwap = getAutoSwap(swapOptionList, asset.id);
    if (autoSwap) {
      if (!_.isEmpty(asset.ancestors)) {
        changeAssetId((_.last(asset.ancestors) as any).id);
      }
      if (autoSwap.invalidSwapOuts?.length > 0) {
        const NAMES = _.map(autoSwap.invalidSwapOuts, (s) => s.item.name).join(', ');
        notifyWarning(i18next.t('ASSET_REPLACE.INVALID', { NAMES }));
      }
      const swapPairs = getSwapPairs(autoSwap);

      return swapAssetsFn(swapPairs);
    } else if (swapOptionList.swapOptions.length > 0) {
      if (headlessRenderMode()) {
        notifyWarning(i18next.t('ASSET_REPLACE.MULTI_SWAP_COMING_LATER'));
        return Promise.reject('Multi-asset swap not supported');
      } else {
        const { availableAssets, likelySwapOuts } = getSwapRootChoices(swapOptionList, asset.id);
        const modalSize = _.max(_.map(availableAssets, 'formattedName.length')) > 50 ? 'lg' : 'sm';
        setShowModal(AssetReplaceModal, true, {
          selectedAsset: asset,
          availableAssets,
          likelySwapOuts,
          onAssetSelection: ({ id }) => {
            const swapOption = swapOptionList.swapOptions.find((option) => option.swapRootCandidate.id === id);
            return swapAssets(getSwapPairs(swapOption)).catch(_.noop);
          },
          size: modalSize,
          onClose: () => setShowModal(AssetReplaceModal, false),
        } as AssetReplaceModalProps);
      }
    } else if (swapOutItemIds.length) {
      if (headlessRenderMode()) {
        // TODO: CRAB-30040: Change how this warning is handled.
        notifyWarning(i18next.t('ASSET_REPLACE.INVALID_ALL'));
      } else {
        warnToast({ messageKey: 'ASSET_REPLACE.NOT_POSSIBLE' });
      }
    }
  });
}
