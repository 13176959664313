import { SeeqNames } from '@/main/app.constants.seeqnames';
import { getCsrfToken } from '@/utilities/auth.utilities';
import { generateRequestId, getOriginLabel, getOriginUrl } from '@/utilities/http.utilities';
import { AssistantOrigin, ChatMessage } from './aiAssistant.types';

function headers() {
  return {
    [SeeqNames.API.Headers.Csrf]: getCsrfToken(),
    [SeeqNames.API.Headers.RequestOrigin]: 'Analysis',
    [SeeqNames.API.Headers.RequestOriginURL]: getOriginUrl(),
    [SeeqNames.API.Headers.RequestOriginLabel]: getOriginLabel(),
    [SeeqNames.API.Headers.RequestId]: generateRequestId(),
  };
}

/**
 * Gets the Chats for the current user.
 *
 * @returns  A chronologically ordered dictionary of chat conversations
 */
export async function getChats(): Promise<Record<string, { messages: ChatMessage[]; origin?: AssistantOrigin }>> {
  const response = await fetch('/genai/chats', { method: 'GET', headers: headers() });
  return await response.json();
}

export async function deleteChat(chatId: string): Promise<void> {
  await fetch(`/genai/chats?chat_id=${chatId}`, { method: 'DELETE', headers: headers() });
}
