import React, { useEffect } from 'react';
import { AnswerType, AssistantOrigin, LevelOfSatisfaction } from '@/aiAssistant/aiAssistant.types';
import { HoveredIconComponent } from '@/aiAssistant/feedback/HoveredIcon.atom';
import { FeedbackDialog } from '@/aiAssistant/feedback/FeedbackDialog.atom';

const wrongAnswer: AnswerType = {
  problemKey: 'wrong',
  displayKey: 'AI_ASSISTANT.FEEDBACK.WRONG_ANSWER',
  correctionSuggestion: 'AI_ASSISTANT.FEEDBACK.WRONG_ANSWER_SUGGESTION',
  correctionPrompt: 'AI_ASSISTANT.FEEDBACK.WRONG_ANSWER_PROMPT',
};

const tooComplexAnswer: AnswerType = {
  problemKey: 'tooComplex',
  displayKey: 'AI_ASSISTANT.FEEDBACK.TOO_COMPLICATED_ANSWER',
  correctionSuggestion: 'AI_ASSISTANT.FEEDBACK.TOO_COMPLICATED_ANSWER_SUGGESTION',
  correctionPrompt: 'AI_ASSISTANT.FEEDBACK.TOO_COMPLICATED_ANSWER_PROMPT',
};

const unclearAnswer: AnswerType = {
  problemKey: 'unclear',
  displayKey: 'unclear',
  correctionSuggestion: 'Ask me to clarify',
  correctionPrompt: 'Can you elaborate on this? I am not sure I fully understand your suggestion.',
};

const otherAnswer: AnswerType = {
  problemKey: 'other',
  displayKey: 'other',
  correctionSuggestion: 'Tell us more!',
  showInput: true,
};

const consiseAnswer: AnswerType = {
  problemKey: 'concise',
  displayKey: 'concise',
};
const accurateAnswer: AnswerType = {
  problemKey: 'accurate',
  displayKey: 'accurate',
};
const detailed: AnswerType = {
  problemKey: 'detailed',
  displayKey: 'detailed',
};

const moreConsiceAnswer: AnswerType = {
  problemKey: 'moreConcise',
  displayKey: 'too wordy',
  correctionSuggestion: 'Ask me to simplfy!',
  correctionPrompt: 'Can you provide a shorter, more concise answer?',
};

const moreDetailAnswer: AnswerType = {
  problemKey: 'moreDetail',
  displayKey: 'detail',
  correctionSuggestion: 'Ask me to elaborate!',
  correctionPrompt: 'Can you give me more details?',
};

const examplesAnswer: AnswerType = {
  problemKey: 'examples',
  displayKey: 'examples',
  correctionSuggestion: 'Ask me for an example!',
  correctionPrompt: 'Can you give me an example?',
};

const moreAccuracyAnswer: AnswerType = {
  problemKey: 'moreAccuracy',
  displayKey: 'accuracy',
};

const notAtAllOptions: AnswerType[] = [wrongAnswer, unclearAnswer, tooComplexAnswer, otherAnswer];
const verySatisfiedOptions: AnswerType[] = [consiseAnswer, accurateAnswer, detailed, otherAnswer];
const notReallyOptions: AnswerType[] = [moreConsiceAnswer, moreDetailAnswer, moreAccuracyAnswer, examplesAnswer];

const satisfactionConfig = [
  {
    icon: 'fa-face-sad-cry',
    satisfaction: 'notAtAll',
    title: 'AI_ASSISTANT.FEEDBACK.NOT_AT_ALL',
    text: 'AI_ASSISTANT.FEEDBACK.NOT_AT_ALL_SUB',
    options: notAtAllOptions,
  },
  {
    icon: 'fa-face-frown',
    satisfaction: 'notReally',
    title: 'AI_ASSISTANT.FEEDBACK.NOT_REALLY',
    options: notReallyOptions,
  },
  {
    icon: 'fa-face-smile',
    satisfaction: 'satisfied',
    title: 'AI_ASSISTANT.FEEDBACK.SATISFIED',
    text: 'AI_ASSISTANT.FEEDBACK.SATISFIED_SUB',
    options: verySatisfiedOptions,
  },
  {
    icon: 'fa-face-grin-hearts',
    satisfaction: 'verySatisfied',
    title: 'AI_ASSISTANT.FEEDBACK.VERY_SATISFIED',
    text: 'AI_ASSISTANT.FEEDBACK.VERY_SATISFIED_SUB',
  },
];

const iconOffset = 30;

interface FeedbackProps {
  onSubmitPrompt: (prompt: string, origin: AssistantOrigin, context?: Record<string, unknown>) => void;
  origin: AssistantOrigin;
  scrollToBottom: () => void;
}

export const Feedback: React.FunctionComponent<FeedbackProps> = ({ onSubmitPrompt, origin, scrollToBottom }) => {
  const [satisfaction, setSatisfaction] = React.useState<LevelOfSatisfaction | undefined>();
  const satisfactionEntry = satisfactionConfig.find((entry) => entry.satisfaction === satisfaction);
  useEffect(() => {
    if (satisfaction !== undefined) {
      requestAnimationFrame(scrollToBottom);
    }
  }, [satisfaction]);

  useEffect(() => {
    requestAnimationFrame(scrollToBottom);
  }, []);

  const feedbackIcons = (
    <div className="aiFeedbackIconContainer mb40">
      {satisfactionConfig.map((config, index) => (
        <HoveredIconComponent
          key={config.satisfaction}
          left={iconOffset * index}
          icon={config.icon}
          satisfaction={config.satisfaction as LevelOfSatisfaction}
          setSatisfaction={setSatisfaction}
        />
      ))}
    </div>
  );
  const feedbackDialog = satisfactionEntry ? (
    <FeedbackDialog
      onSubmitPrompt={onSubmitPrompt}
      origin={origin}
      setSatisfaction={setSatisfaction}
      icon={satisfactionEntry.icon}
      title={satisfactionEntry.title}
      text={satisfactionEntry.text}
      satisfaction={satisfactionEntry.satisfaction as LevelOfSatisfaction}
      options={satisfactionEntry.options}
      scrollToBottom={scrollToBottom}
    />
  ) : (
    <></>
  );

  return (
    <div className="flexRowContainer flexFill flexAlignCenter pb10 mt10">
      <div className="width-maximum">{!satisfactionEntry ? feedbackIcons : feedbackDialog}</div>
    </div>
  );
};
