import React, { useRef, useState } from 'react';
import { useChatStream } from '@/aiAssistant/useChatStream';
import { AiAssistantPrompt } from '@/aiAssistant/AiAssistantPrompt.molecule';
import { v1 as timeOrderedUUID } from 'uuid';
import { AiAssistantChatView } from '@/aiAssistant/AiAssistantChatView.molecule';
import { ChatMessage } from '@/aiAssistant/aiAssistant.types';
import { FormulaAiAssistantIntroduction } from '@/aiAssistant/FormulaAiAssistantIntroduction.atom';

interface AiAssistantProps {
  insertFormulaSnippet: (snippet: string) => void;
}

export const FormulaAiAssistant: React.FunctionComponent<AiAssistantProps> = ({ insertFormulaSnippet }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const chatId = useRef(timeOrderedUUID());
  const displayRef = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
  const { submitPrompt, isRunning, abortRequest, scrollToBottom } = useChatStream(
    'formula',
    chatId,
    setMessages,
    displayRef,
  );

  return (
    <div className="flexRowContainer flexFill">
      <AiAssistantChatView
        isRunning={isRunning}
        messages={messages}
        insertFormulaSnippet={insertFormulaSnippet}
        selectedAgent={'formula'}
        origin="workbench"
        submitPrompt={submitPrompt}
        scrollToBottom={scrollToBottom}
        ref={displayRef}>
        <FormulaAiAssistantIntroduction submitPrompt={submitPrompt} />
      </AiAssistantChatView>
      <AiAssistantPrompt
        submitPrompt={submitPrompt}
        isRunning={isRunning}
        origin="workbench"
        abortRequest={abortRequest}
        selectedAgent={'formula'}
      />
    </div>
  );
};
